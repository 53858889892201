@media print {
    body * {
      visibility: hidden;
    }
    #printableSection, #printableSection * {
      visibility: visible;
    }
    #printableSection {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      overflow: visible;
      opacity: 1; /* Ensure full opacity */
    }
  }
  